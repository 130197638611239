import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { useField } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import LaunchIcon from "@mui/icons-material/Launch";
import debounce from "lodash/debounce"; // Ensure lodash.debounce is installed

// New component to render text with clickable URLs
const TextWithLinks = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return (
    <span>
      {parts.map((part, i) =>
        urlRegex.test(part) ? (
          <Link key={i} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </Link>
        ) : (
          part
        )
      )}
    </span>
  );
};

const CustomEmailField = React.memo(({ mode, data, ...outerProps }) => {
  const [field, meta, helpers] = useField(data.name);
  const readMode = mode === "read";

  // Initialize local state with Formik's field value for immediate feedback
  const [localValue, setLocalValue] = useState(field.value);

  // Update Formik's state with debounced value changes
  const debouncedSetValue = useCallback(debounce(helpers.setValue, 300), []);

  useEffect(() => {
    debouncedSetValue(localValue);
    // Cleanup on unmount
    return () => debouncedSetValue.cancel();
  }, [localValue, debouncedSetValue]);

  // Directly update local state for immediate feedback, debounce Formik updates
  const handleChange = useCallback((event) => {
    setLocalValue(event.target.value);
  }, []);

  // Check if the input contains a URL
  const containsUrl = (str) => {
    const urlRegex = /(https?:\/\/(?:www\.)?[^\s]+)/g;
    return urlRegex.test(str);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const matchedUrls = localValue.match(/(https?:\/\/(?:www\.)?[^\s]+)/g);
    if (matchedUrls && matchedUrls.length > 0) {
      window.open(matchedUrls[0], "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ width: "100%" }}>
          <TextField
            {...field}
            {...outerProps}
            label={data.label} // Ensure label is passed here
            value={localValue}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            type="email"
            disabled={readMode}
            InputProps={{
              ...outerProps.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {readMode && containsUrl(localValue) ? (
                    <IconButton onClick={handleLinkClick} size="small">
                      <LaunchIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
              readOnly: readMode,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
});

export default CustomEmailField;
