import React, { useContext, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Link,
  Card,
  CardContent,
  LinearProgress,
  colors,
  Modal,
  Alert,
  TextField,
} from "@mui/material";
import { Formik, Form, useField } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useState } from "react";
import * as Yup from "yup";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";

export const ForgotPasswordModal = ({ handleCloseModal = () => {} }) => {
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [otpSent, setOtpSent] = useState(false);
  const [updatePasswordError, setUpdatePasswordError] = useState(null);
  const [otpResendSuccess, setOtpResendSuccess] = useState(false);

  const [currentUserId, setCurrentUserId] = useState(null);

  const [sendOtpError, setSendOtpError] = useState(null); // New state for error message

  const handleSendOtp = async (
    values,
    { setSubmitting = () => null, setFieldError }
  ) => {
    try {
      setCurrentUserId(values.userId);
      const response = await axiosFetch.get(
        `/user/forgotPassword/${values.userId}`
      );
      console.log("OTP sent successfully:", response);
      setOtpSent(true);
      setSendOtpError(null); // Clear error message on success
    } catch (error) {
      let errorMessage = t("Failed to send OTP. Please try again.");
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorMessage = error.response.data.errorMessage[0];
      }
      console.error("Error updating password:", errorMessage);
      if (errorMessage == "Email does not exist for the user.") {
        errorMessage = t(
          "Email does not exist for the user. Please contact the admin."
        );
      }
      setSendOtpError(errorMessage); // Set error message
    } finally {
      setSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axiosFetch.get(
        `/user/forgotPassword/${currentUserId}`
      );
      console.log("OTP sent successfully:", response);
      setOtpResendSuccess(true); // Set success message
    } catch (error) {
      console.error("Error sending OTP:", error);
      setOtpResendSuccess(false);
    }
  };

  const handleUpdatePassword = async (values, setSubmitting = () => {}) => {
    try {
      const response = await axiosFetch.put(
        `/userPassword/${currentUserId}`,
        {
          newPassword: values.newPassword,
          otp: values.userOtp,
        }
      );
      console.log("Password updated successfully:", response);
      setSubmitting(false);
      handleCloseModal();
      // Handle success (e.g., show a success message, close modal, etc.)
    } catch (error) {
      let errorMessage = t("Failed to update password. Please try again.");
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        errorMessage = error.response.data.errorMessage[0];
      }
      console.error("Error updating password:", errorMessage);
      setUpdatePasswordError(errorMessage);
      setSubmitting(false);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <Typography id="forgot-password-modal" variant="h6" component="h2">
          {t("Forgot Password")}
        </Typography>
      </div>
      {!otpSent ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            userId: "",
          }}
          validationSchema={Yup.object({
            userId: Yup.string().required("Require!"),
          })}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            handleSendOtp(values, { setSubmitting, setFieldError });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div style={{ marginBottom: "16px" }}>
                <CustomTextfield
                  data={{
                    name: "userId",
                    label: `${t("User ID")}`,
                  }}
                />
              </div>
              {sendOtpError && ( // Conditionally render alert
                <div style={{ marginBottom: "16px" }}>
                  <Alert severity="error">{sendOtpError}</Alert>
                </div>
              )}
              <div style={{ marginBottom: "16px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {t("Send OTP")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            userOtp: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            userOtp: Yup.string().required(t("Required!")), // Ensure this matches the field name
            newPassword: Yup.string()
              .required("Required!")
              .min(
                8,
                t(
                  "The password must be a mix of letters, numbers, and symbols with a minimum length of 8 characters."
                )
              )
              .matches(
                /[a-zA-Z]/,
                t(
                  "The password must be a mix of letters, numbers, and symbols with a minimum length of 8 characters."
                )
              )
              .matches(
                /\d/,
                t(
                  "The password must be a mix of letters, numbers, and symbols with a minimum length of 8 characters."
                )
              )
              .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                t(
                  "The password must be a mix of letters, numbers, and symbols with a minimum length of 8 characters."
                )
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
              .required("Required!"),
          })}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            console.log("Submitted values:", values); // Debugging: Check if OTP is captured
            handleUpdatePassword(values, setSubmitting); // Uncomment this line to enable password update
          }}
        >
          {({ isSubmitting, getFieldProps, touched, errors }) => (
            <Form>
              <div style={{ marginBottom: "16px" }}>
                <TextField
                  {...getFieldProps("userOtp")}
                  label={t("Enter OTP")}
                  variant="outlined"
                  fullWidth
                  error={touched.userOtp && Boolean(errors.userOtp)}
                  helperText={touched.userOtp && errors.userOtp}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <CustomTextfield
                  data={{
                    name: "newPassword",
                    label: `${t("New Password")}`,
                  }}
                  type="password"
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <CustomTextfield
                  data={{
                    name: "confirmPassword",
                    label: `${t("Confirm Password")}`,
                  }}
                  type="password"
                />
              </div>
              <div style={{ marginTop: "16px" }}>
                {!updatePasswordError & !otpResendSuccess ? (
                  <Alert severity="success">
                    {t("OTP sent successfully!")}
                  </Alert>
                ) : (
                  <Alert severity="error">{updatePasswordError}</Alert>
                )}
                {otpResendSuccess && (
                  <Alert severity="success">
                    {t("OTP resent successfully!")}
                  </Alert>
                )}
              </div>
              <div style={{ marginTop: "16px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {t("Update Password")}
                </Button>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => handleResendOtp()}
                >
                  {t("Resend OTP")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
