import React, { useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useState } from "react";
import { useNavigate } from "react-router";
import GroupMultiSelect from "../formikInputs/GroupMultiSelect";
import { useLocation } from "react-router";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";
import { AxiosFetch } from "../AxiosFetch";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import DeleteDialog from "../commonComponents/DeleteDialog";
import CustomTextField from "../formikInputs/CustomTextField";
import CustomEmailField from "../formikInputs/CustomEmailField";

export default function UserEdit(props) {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const { userRole, ...context } = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("User Edit");
  }, []);
  const location = useLocation();
  const initialValues = location.state.data;
  const [loading, setLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  var group = [];
  var privilegeAPI = 0;
  //const [togglePassword, setTogglePassword] = useState(false);

  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];

  //   const initialValues = {
  //     userId: "",
  //     userPassword: "",
  //     extNumber: "",
  //     lastName: "",
  //     firstName: "",
  //     lastKana: "",
  //     firstKana: "",
  //     privilege: [],
  //     groupId: [],
  //   };

  //   const formValidation = Yup.object().shape({
  //     id: Yup.string()
  //       .required("Required!")
  //       .min(2, "Too Short!")
  //       .max(10, "Too Long!"),
  //     password: Yup.string().required("Required!"),
  //   });

  const handleSubmit = async (values) => {
    // await new Promise((r) => setTimeout(r, 500));
    console.log(values);
    setLoading(true);

    values.groupId.map((data, index) => {
      group[index] = { groupId: data };
    });
    values.privilege.sort();
    values.privilege.reverse().map((data, index) => {
      privilegeAPI = privilegeAPI + data * Math.pow(10, index);
    });
    values.privilege.reverse();

    // let APIvalues={...values,customerGroups:group,privilege:privilegeAPI}
    var APIvalues = {
      firstName: values.firstName,
      lastName: values.lastName,
      extensionNumber: values.extensionNumber,
      firstKana: values.firstKana,
      lastKana: values.lastKana,
      customerGroups: group,
      privilege: privilegeAPI,
      memo: values.memo,
      email: values.email,
    };
    if (userRole === 3 && values.userPassword !== "####") {
      APIvalues.userPassword = values.userPassword;
    }
    delete APIvalues.groupId;
    privilegeAPI = 0;
    try {
      const custResponse = await axiosFetch.put(
        `/user/${values.userId}`,
        JSON.stringify(APIvalues)
      );
      console.log(custResponse);
      setLoading(false);
      navigate("/userDetail", { state: { userId: values.userId } });
    } catch (error) {
      alert("error");
      console.log(error);
      setLoading(false);
    }
    // alert(JSON.stringify(APIvalues, null, 2));
  };
  const handleDelete = async (e) => {
    console.log(initialValues, "ppppp");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(`/user/${initialValues.userId}`);
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      navigate("/userSearch");
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "10%",
              py: "5%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <DeleteDialog
              handleDialogOpen={handleDialogOpen}
              handleDelete={handleDelete}
              dialogOpen={dialogOpen}
              delLoading={delLoading}
            />
            <Card elevation={4} sx={{ border: "10px solid #fff077" }}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item md={userRole === 3 ? 6 : 4} xs={6}>
                    <CustomTextfield
                      data={{ name: "userId", label: `${t("User ID")}` }}
                      mode="read"
                    />
                  </Grid>
                  {/* {userRole === 3 ? (
                    <Grid item md={6} xs={6}>
                      <CustomTextfield
                        data={{ name: "userPassword", label: "Password" }}
                      />
                    </Grid>
                  ) : null} */}

                  <Grid item xs={6} md={userRole === 3 ? 6 : 4}>
                    <CustomTextfield
                      data={{
                        name: "extensionNumber",
                        label: `${t("extension number")}`,
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item md={userRole === 3 ? 6 : 4} xs={6}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "privilege",
                        label: `${t("User role")}`,
                        list: list,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{ name: "lastName", label: `${t("Last Name")}` }}
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{ name: "firstName", label: `${t("First Name")}` }}
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "lastKana",
                        label: `${t("Last Kana Name")}`,
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "firstKana",
                        label: `${t("First Kana Name")}`,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomEmailField
                      data={{
                        name: "email",
                        label: `${t("Email")}`,
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "registerDateTime",
                        label: `${t("Created Date")}`,
                      }}
                      type="datatime-local"
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "modifyDateTime",
                        label: `${t("Last Updated Date")}`,
                      }}
                      type="datatime-local"
                      mode="read"
                    />
                  </Grid>

                  {/* <Grid item md={12}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "groupIddd",
                        label: "Select Group",
                        list: pp,
                      }}
                    />
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <GroupMultiSelect name="groupId" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      multiline
                      minRows={3}
                      data={{ name: "memo", label: `${t("Memo")}` }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      fullWidth
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      color="error"
                      //onClick={handleDelete}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      {t("Delete")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
