import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  colors,
  Modal,
  InputAdornment,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import logo from "../testFolder/logo.jpg";
import * as Yup from "yup";
import CustomSelect from "../formikInputs/CustomSelect";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import LockResetIcon from "@mui/icons-material/LockReset";
import InfoIcon from "@mui/icons-material/Info";
import { ForgotPasswordModal } from "./ForgotPasswordModal";

// Login Form Component
const LoginForm = ({ onLoginSuccess, loading }) => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const a = useContext(LoginContext);
  const [togglePassword, setTogglePassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];

  const initialValues = {
    userName: "",
    userPassword: "",
    reqPrivilege: 3,
  };

  const formValidation = Yup.object().shape({
    userName: Yup.string()
      .required("Required!")
      .min(2, "Too Short!")
      .max(10, "Too Long!"),
    userPassword: Yup.string().required("Required!"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const userResponse = await axiosFetch.post(
        "/authenticate",
        JSON.stringify(values)
      );

      if (userResponse.data.jwtToken === "OTP_REQUIRED") {
        // Pass userId to parent component for OTP verification
        onLoginSuccess(userResponse.data.user.userId);
      } else {
        // Handle direct login without OTP (fallback)
        handleDirectLogin(userResponse);
      }
    } catch (err) {
      console.log(err, "errr");
      a.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${err.response.data.errorMessage}`,
      });
      resetForm();
    }
  };

  const handleDirectLogin = (response) => {
    a.setToken(true);
    sessionStorage.setItem("jwtToken", response.data.jwtToken);
    sessionStorage.setItem("sideBarOpen", true);
    // Continue with login success
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      <Form>
        <Box
          sx={{
            alignItems: "center",
            px: "25%",
            py: "2%",
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <Card elevation={4}>
            <LinearProgress
              sx={{ visibility: `${!loading ? "hidden" : ""}` }}
            />
            <CardContent>
              <Grid container columnSpacing={1} rowSpacing={4}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                  style={{ display: "none" }}
                >
                  <img src={logo} alt="Topline"></img>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h4" align="center">
                    {t("CTI Log In")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextfield
                    data={{ name: "userName", label: `${t("User ID")}` }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextfield
                    data={{ name: "userPassword", label: `${t("Password")}` }}
                    type={togglePassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {togglePassword ? (
                            <Visibility
                              onClick={() => setTogglePassword(false)}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => setTogglePassword(true)}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSelect
                    data={{
                      name: "reqPrivilege",
                      label: `${t("Select role")}`,
                      list: list,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    {t("Log In")}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body1"
                    color={colors.green[900]}
                    sx={{
                      underlinedText: {
                        textDecoration: "underline",
                      },
                      cursor: "pointer",
                    }}
                    onClick={handleOpenModal}
                  >
                    <InfoIcon sx={{ color: colors.orange[700] }} />
                    {t("Forgot password? Ask Admin User to reset it")}
                    <LockResetIcon />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="forgot-password-modal"
          aria-describedby="forgot-password-description"
        >
          <ForgotPasswordModal handleCloseModal={handleCloseModal} />
        </Modal>
      </Form>
    </Formik>
  );
};

// OTP Verification Component
const OtpVerificationForm = ({ userId, onOtpVerified, onBack, loading }) => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const a = useContext(LoginContext);
  const navigate = useNavigate();

  const otpInitialValues = {
    otp: "",
  };

  const otpValidation = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const handleVerifyOtp = async (values, { resetForm }) => {
    try {
      const otpResponse = await axiosFetch.get(
        `/verify-otp/${userId}/${values.otp}`
      );

      // Handle successful OTP verification
      a.setToken(true);
      sessionStorage.setItem("jwtToken", otpResponse.data.jwtToken);
      sessionStorage.setItem("sideBarOpen", true);
      resetForm();
      navigate("/customerSearch");
    } catch (err) {
      console.log(err, "errr");
      a.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${err.response.data.errorMessage || "Invalid OTP"}`,
      });
    }
  };

  return (
    <Formik
      initialValues={otpInitialValues}
      validationSchema={otpValidation}
      onSubmit={handleVerifyOtp}
    >
      <Form>
        <Box
          sx={{
            alignItems: "center",
            px: "25%",
            py: "2%",
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <Card elevation={4}>
            <LinearProgress
              sx={{ visibility: `${!loading ? "hidden" : ""}` }}
            />
            <CardContent>
              <Grid container columnSpacing={1} rowSpacing={4}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h4" align="center">
                    {t("Enter OTP")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    {t(
                      "A one-time passcode has been sent to your registered contact information"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextfield
                    data={{ name: "otp", label: `${t("OTP")}` }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    {t("Verify")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onClick={onBack}
                  >
                    {t("Back to Login")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Form>
    </Formik>
  );
};

// Main Component to handle login flow
export default function LoginIndex(props) {
  const { t } = useTranslation();
  const a = useContext(LoginContext);

  useEffect(() => {
    a.setPageTitle("Login");
    a.setUserId("");
    sessionStorage.clear();
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") < 0) {
        sessionStorage.setItem("safariFlag", true);
      } else {
        sessionStorage.setItem("safariFlag", false);
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [userId, setUserId] = useState("");

  const handleLoginSuccess = (userId) => {
    setUserId(userId);
    setShowOtpForm(true);
    setLoading(false);
  };

  const handleBackToLogin = () => {
    setShowOtpForm(false);
    setUserId("");
  };

  return (
    <div>
      {!showOtpForm ? (
        <LoginForm onLoginSuccess={handleLoginSuccess} loading={loading} />
      ) : (
        <OtpVerificationForm
          userId={userId}
          onBack={handleBackToLogin}
          loading={loading}
        />
      )}
    </div>
  );
}
