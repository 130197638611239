import React, { useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router";
import GroupMultiSelect from "../formikInputs/GroupMultiSelect";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";
import { AxiosFetch } from "../AxiosFetch";
import LoginContext from "../../context/LoginContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CustomTextField from "../formikInputs/CustomTextField";
import CustomEmailField from "../formikInputs/CustomEmailField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function CreateNewUser(props) {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Create New User");
  }, []);

  var group = [];
  var privilegeAPI = 0;
  const [togglePassword, setTogglePassword] = useState(false);
  const [pp, setpp] = useState();
  let submitAction;
  const [loading, setLoading] = useState(false);

  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];
  const variableList = [
    { value: 100, name: "Oliver Hansen" },
    { value: 101, name: "Van Henry" },
    { value: 102, name: "Oliver Hansen" },
    { value: 103, name: "Van Henry" },
    { value: 104, name: "Oliver Hansen" },
    { value: 105, name: "Van Henry" },
  ];
  if (typeof pp === "undefined") {
    setpp(variableList);
  }

  const initialValues = {
    userId: "",
    userPassword: "",
    extensionNumber: "",
    lastName: "",
    firstName: "",
    lastKana: "",
    firstKana: "",
    privilege: [],
    groupId: [],
    memo: "",
  };

  const formValidation = Yup.object().shape({
    userId: Yup.string().required(`${t("This field is required")}`),
    userPassword: Yup.string().required(`${t("This field is required")}`),
    firstName: Yup.string().required(`${t("This field is required")}`),
  });

  const handleSubmit = async (values, e) => {
    setLoading(true);
    console.log(values, "vvvv");
    // await new Promise((r) => setTimeout(r, 500));

    values.groupId.map((data, index) => {
      group[index] = { groupId: data };
    });

    values.privilege.sort();
    values.privilege.reverse().map((data, index) => {
      privilegeAPI = privilegeAPI + data * Math.pow(10, index);
    });
    values.privilege.reverse();

    var APIvalues = {
      ...values,
      customerGroups: group,
      privilege: privilegeAPI,
    };
    delete APIvalues.groupId;
    privilegeAPI = 0;

    try {
      const custResponse = await axiosFetch.post(
        "/registerNewUser",
        JSON.stringify(APIvalues)
      );
      console.log(custResponse, "rrrr");
      setLoading(false);
      if (submitAction === "primary") navigate("/userSearch");
      else {
        e.resetForm();
      }
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setLoading(false);
    }
  };
  return (
    <div>
      {/* <ButtonAppBar title="New User" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(values, e) => {
          // console.log(values, "vvvvv");
          handleSubmit(values, e);
          // if (submitAction === "primary") {
          //   console.log("Entered primary submit type");
          //   handleSubmit(values);
          //   //navigate("/userSearch");
          // } else {
          //   console.log("Entered secondary submit type");
          //   handleSubmit(values);
          //   //window.location.reload();
          // }
        }}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "10%",
              py: "5%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item md={4} xs={6}>
                    <CustomTextfield
                      data={{ name: "userId", label: `${t("User ID")}` }}
                      // type="number"
                    />
                  </Grid>
                  {/* <Grid item md={4} xs={12}>
                    <CustomTextfield
                      data={{ name: "userName", label: "User Name" }}
                    />
                  </Grid> */}
                  <Grid item md={4} xs={6}>
                    <CustomTextfield
                      data={{ name: "userPassword", label: `${t("Password")}` }}
                      type={togglePassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {" "}
                            {togglePassword ? (
                              <Visibility
                                onClick={(e) => {
                                  setTogglePassword(false);
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                onClick={(e) => {
                                  setTogglePassword(true);
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomTextfield
                      data={{
                        name: "extensionNumber",
                        label: `${t("extension number")}`,
                      }}
                      type="number"
                    />
                  </Grid>

                  <Grid item md={4} xs={6}>
                    <CustomTextfield
                      data={{ name: "lastName", label: `${t("Last Name")}` }}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <CustomTextfield
                      data={{ name: "firstName", label: `${t("First Name")}` }}
                    />
                  </Grid>
                  <Grid item md={3.5} xs={5.5}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "privilege",
                        label: `${t("Select role")}`,
                        list: list,
                      }}
                    />
                  </Grid>
                  <Grid item md={0.5} xs={0.5}>
                    <Tooltip
                      title={
                        <div>
                          {t(`Set the user's "role".`)}
                          <a
                            href="https://docs.google.com/spreadsheets/d/11M0EnWjh92KEZbivP10A7-DpToj_7ZyYxi3-e651q8g/edit#gid=0"
                            style={{ color: "lightblue" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("Click Here")}
                          </a>
                          {t("for details")}
                        </div>
                      }
                      fontSize="small"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "lastKana",
                        label: `${t("Last Kana Name")}`,
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "firstKana",
                        label: `${t("First Kana Name")}`,
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={6}>
                    <CustomEmailField
                      data={{
                        name: "email",
                        label: `${t("Email")}`,
                      }}
                    />
                  </Grid>

                  {/* <Grid item md={12}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "groupIddd",
                        label: "Select Group",
                        list: pp,
                      }}
                    />
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <GroupMultiSelect name="groupId" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      multiline
                      minRows={3}
                      data={{ name: "memo", label: `${t("Memo")}` }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      fullWidth
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        submitAction = "primary";
                        // handleSubmit();
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        submitAction = "secondary";
                        // handleSubmit();
                      }}
                    >
                      {t("Save and New")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
